import { roundToFractions } from '../helpers';
import { countryToCurrency, type CurrencyCode, type PriceType } from '../model/Price';

import { Country } from './Country';
import { getYear } from './DateTime';
import { Logger } from './Logger';

export enum CalculatorType {
  SIMPLE = 'simple',
  ADVANCED = 'advanced',
}

export interface VehicleEarningsInput {
  country: Country;
  currency: CurrencyCode;
  pricePerDayCoefficient?: number;
  vehicleFreeDays: number;
  vehicleMarketPrice: number;
}

export interface InitialValues {
  vehicleManufactureYear: number;
  vehicleManufactureYearMin: number;
  vehiclePrice: number;
  vehiclePrice_max: number;
  vehiclePrice_min: number;
  vehiclePrice_step: number;
  vehicleYearlyMileage: number;
  vehicleYearlyMileageMax: number;
  vehicleYearlyMileageMin: number;
}

export interface VehicleTierInput {
  country: Country;
  currency: CurrencyCode;
  vehicleAge: number;
  vehicleMarketPrice: number;
  vehicleYearlyMileage: number;
}

export interface VehicleExpenses {
  administrativeCosts: number;
  depreciationCosts: number;
  fuelCosts: number;
  insuranceCosts: number;
  maintenanceCosts: number;
  otherCosts: number;
  repairsCosts: number;
}

export type VehicleExpensesInput = VehicleTierInput & Partial<VehicleExpenses>;

interface Group<Field, Item> {
  group: (Item & {
    fieldValue: Field;
  })[];
}

interface TierItem {
  max: string;
  min: string;
  tier_id: string;
}

interface CostItem {
  administrative_costs: string;
  age_tier: string;
  depreciation_costs: string;
  insurance_costs: string;
  maintenance_costs: string;
  mileage_tier: string;
  other_costs: string;
  price_tier: string;
  repairs_costs: string;
}

export interface VehicleExpensesCalculationData {
  ageTiers: Group<Country, { nodes: TierItem[] }>;
  costs: Group<Country, { nodes: CostItem[] }>;
  fuelCosts: Group<Country, { nodes: { currency: CurrencyCode; amount: string }[] }>;
  mileageTiers: Group<Country, { nodes: TierItem[] }>;
  priceTiers: Group<Country, Group<string, { nodes: TierItem[] }>>;
}

const WEEKS_IN_YEAR = 52;
const PRICE_PER_DAY_COEFFICIENT = 0.0019;
const FUEL_CONSUMPTION = 6; // l/100km
const absoluteVehicleCosts: (keyof CostItem)[] = ['administrative_costs'];
const dynamicInitialValuesForCountries: Country[] = [Country.CZ];
const earningsLimits: Record<Country, [number, number]> = {
  [Country.CZ]: [700_000, 0.0488],
  [Country.SK]: [28_000, 0.0488],
};

export class VehicleEarningsCalculator {
  private logger = new Logger('VehicleEarningsCalculator', {
    [Logger.Level.DEBUG]: process.env.GATSBY_ENV !== 'production',
    [Logger.Level.WARNING]: false,
    [Logger.Level.ERROR]: true,
  });
  private initialValues: Record<Country, InitialValues> = {
    [Country.CZ]: {
      vehicleManufactureYear: 2017,
      vehicleManufactureYearMin: 2000,
      vehiclePrice: 340_000,
      vehiclePrice_max: 1_500_000,
      vehiclePrice_min: 50_000,
      vehiclePrice_step: 10_000,
      vehicleYearlyMileage: 14_000,
      vehicleYearlyMileageMax: 35_000,
      vehicleYearlyMileageMin: 5_000,
    },
    [Country.SK]: {
      vehicleManufactureYear: 2017,
      vehicleManufactureYearMin: 2000,
      vehiclePrice: 14_000,
      vehiclePrice_max: 60_000,
      vehiclePrice_min: 2_000,
      vehiclePrice_step: 400,
      vehicleYearlyMileage: 14_000,
      vehicleYearlyMileageMax: 35_000,
      vehicleYearlyMileageMin: 5_000,
    },
  };

  constructor(private readonly data: VehicleExpensesCalculationData) {
    for (const country of Object.values(Country)) {
      const ageTiers = this.findGroup(this.data.ageTiers, country)?.nodes ?? [];
      let vehicleManufactureYearMin = this.initialValues[country].vehicleManufactureYear;
      for (const tier of ageTiers) {
        const min = VehicleEarningsCalculator.ageToManufactureYear(toNumber(tier.max));
        if (min < vehicleManufactureYearMin) vehicleManufactureYearMin = min;
      }
      this.initialValues[country].vehicleManufactureYearMin = vehicleManufactureYearMin;

      const mileageTiers = this.findGroup(this.data.mileageTiers, country)?.nodes ?? [];
      let vehicleYearlyMileageMin = this.initialValues[country].vehicleYearlyMileage;
      let vehicleYearlyMileageMax = this.initialValues[country].vehicleYearlyMileage;
      for (const tier of mileageTiers) {
        const min = toNumber(tier.min);
        const max = toNumber(tier.max);
        if (min < vehicleYearlyMileageMin) vehicleYearlyMileageMin = min;
        if (max > vehicleYearlyMileageMax) vehicleYearlyMileageMax = max;
      }
      this.initialValues[country].vehicleYearlyMileageMin = vehicleYearlyMileageMin;
      this.initialValues[country].vehicleYearlyMileageMax = vehicleYearlyMileageMax;
    }
  }

  public calculateVehicleEarnings(input: VehicleEarningsInput): PriceType {
    const { pricePerDayCoefficient = PRICE_PER_DAY_COEFFICIENT, vehicleMarketPrice, vehicleFreeDays } = input;
    const coefficient = pricePerDayCoefficient * WEEKS_IN_YEAR;
    let amount: number = coefficient * vehicleFreeDays * vehicleMarketPrice;
    if (input.country in earningsLimits) {
      const [limit, convergenceCoefficient] = earningsLimits[input.country];
      if (vehicleMarketPrice > limit) {
        amount =
          coefficient * vehicleFreeDays * limit +
          convergenceCoefficient * (vehicleMarketPrice - limit) * vehicleFreeDays;
      }
    }

    return {
      amount,
      currency: input.currency,
    };
  }

  public calculateVehicleExpenses(input: VehicleExpensesInput): PriceType {
    const expenses = this.findVehicleExpenses(input);
    const amount = Object.values(expenses).reduce((sum, value = 0) => sum + value, 0);

    return {
      amount,
      currency: input.currency,
    };
  }

  public calculateExpenseCoverage(expenses: number, earnings: number): number {
    return (earnings / expenses) * 100;
  }

  public findVehicleExpenses(input: VehicleExpensesInput): VehicleExpenses {
    const ageTier = this.findTier(this.findGroup(this.data.ageTiers, input.country)?.nodes, input.vehicleAge);
    if (!ageTier) {
      this.logger.error(`Age tier not found for vehicle age`, { input });
      throw new Error(`Age tier not found for vehicle age: ${input.vehicleAge}`);
    }
    const mileageTier = this.findTier(
      this.findGroup(this.data.mileageTiers, input.country)?.nodes,
      input.vehicleYearlyMileage,
    );
    if (!mileageTier) {
      this.logger.error(`Mileage tier not found for vehicle mileage`, {
        input,
      });
      throw new Error(`Mileage tier not found for vehicle mileage: ${input.vehicleYearlyMileage}`);
    }
    const priceTier = this.findTier(
      this.findGroup(this.findGroup(this.data.priceTiers, input.country), ageTier.tier_id)?.nodes,
      input.vehicleMarketPrice,
    );
    if (!priceTier) {
      this.logger.error(`Price tier not found for vehicle price`, { input });
      throw new Error(
        `Price tier not found for vehicle price: ${input.vehicleMarketPrice} and age tier: ${ageTier.tier_id}`,
      );
    }

    const countryCosts = this.findGroup(this.data.costs, input.country)?.nodes;
    if (!countryCosts) {
      this.logger.error(`Costs not found for country`, { input });
      throw new Error(`Costs not found for country: ${input.country}`);
    }

    const costs = this.findCostsForTiers(countryCosts, ageTier.tier_id, priceTier.tier_id, mileageTier.tier_id);

    return {
      administrativeCosts: this.findCosts(
        costs,
        input.vehicleMarketPrice,
        'administrative_costs',
        input.administrativeCosts,
      ),
      depreciationCosts: this.findCosts(costs, input.vehicleMarketPrice, 'depreciation_costs', input.depreciationCosts),
      fuelCosts: this.findFuelCosts(this.data.fuelCosts, input.country, input.vehicleYearlyMileage, input.fuelCosts),
      insuranceCosts: this.findCosts(costs, input.vehicleMarketPrice, 'insurance_costs', input.insuranceCosts),
      maintenanceCosts: this.findCosts(costs, input.vehicleMarketPrice, 'maintenance_costs', input.maintenanceCosts),
      otherCosts: this.findCosts(costs, input.vehicleMarketPrice, 'other_costs', input.otherCosts),
      repairsCosts: this.findCosts(costs, input.vehicleMarketPrice, 'repairs_costs', input.repairsCosts),
    };
  }

  public findInitialValues(country: Country, type: CalculatorType, vehicleManufactureYear?: number): InitialValues {
    const initialValues = { ...this.initialValues[country] };
    if (type !== CalculatorType.ADVANCED || !dynamicInitialValuesForCountries.includes(country)) {
      return initialValues;
    }
    vehicleManufactureYear ??= initialValues.vehicleManufactureYear;
    const ageTier = this.findTier(
      this.findGroup(this.data.ageTiers, country)?.nodes,
      VehicleEarningsCalculator.manufactureYearToAge(vehicleManufactureYear),
    );
    if (!ageTier) {
      this.logger.error(`Age tier not found for vehicle manufacture year`, {
        country,
        vehicleManufactureYear,
      });
      return initialValues;
    }
    const countryPriceTiers = this.findGroup(this.data.priceTiers, country);
    const agePriceTiers = this.findGroup(countryPriceTiers, ageTier.tier_id)?.nodes ?? [];
    let vehiclePrice_min = Number.MAX_SAFE_INTEGER;
    let vehiclePrice_max = 0;
    for (const tier of agePriceTiers) {
      const min = toNumber(tier.min),
        max = toNumber(tier.max);
      if (min < vehiclePrice_min) vehiclePrice_min = min;
      if (max > vehiclePrice_max) vehiclePrice_max = max;
    }
    initialValues.vehiclePrice_min = vehiclePrice_min;
    initialValues.vehiclePrice_max = vehiclePrice_max;
    return initialValues;
  }

  public static manufactureYearToAge(manufactureYear: number): number {
    return getYear(Date.now()) - manufactureYear;
  }

  public static ageToManufactureYear(age: number): number {
    return getYear(Date.now()) - age;
  }

  private findGroup<Field, TResult>(group: Group<Field, TResult>, value: Field): TResult {
    if (!group) return null;
    return group.group.find(item => item.fieldValue.toString().toLowerCase() === value.toString().toLowerCase());
  }

  private findTier(tiers: TierItem[], value: number): TierItem {
    if (!tiers) return null;
    return tiers.find(tier => {
      const min = toNumber(tier.min);
      const max = toNumber(tier.max);
      return value >= min && value <= max;
    });
  }

  private findCostsForTiers(
    countryCosts: CostItem[],
    ageTier: string,
    priceTier: string,
    mileageTier: string,
  ): CostItem {
    const costs = countryCosts.find(
      it => it.age_tier === ageTier && it.price_tier === priceTier && it.mileage_tier === mileageTier,
    );
    this.logger.debug(`findCosts`, { ageTier, priceTier, mileageTier, costs });
    return costs;
  }

  private findCosts(
    cost: CostItem,
    vehiclePrice: number,
    costName: keyof CostItem,
    inputValue: number | undefined,
  ): number {
    if (typeof inputValue === 'number') return inputValue;
    if (!cost) return null;
    if (absoluteVehicleCosts.includes(costName)) return toNumber(cost[costName]);
    const percentage = roundToFractions(toNumber(cost[costName]), 3);
    return roundToFractions(vehiclePrice * percentage, 0);
  }

  private findFuelCosts(
    groups: VehicleExpensesCalculationData['fuelCosts'],
    country: Country,
    yearlyMileage: number,
    value: number,
  ): number {
    if (typeof value === 'number') return value;
    const countryFuelCosts = this.findGroup(groups, country);
    if (!countryFuelCosts) return null;
    const currency = countryToCurrency(country);
    const fuelCost = countryFuelCosts.nodes.find(it => it.currency.toLowerCase() === currency.toLowerCase());
    if (!fuelCost) return null;
    return (yearlyMileage / 100) * FUEL_CONSUMPTION * toNumber(fuelCost.amount);
  }
}

function toNumber(value: string | number): number {
  if (typeof value === 'number') return value;
  if (value.match(/,\d+\./)) return Number(value.replace(',', ''));
  return Number(value.replace(',', '.'));
}
