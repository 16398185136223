import React from 'react';
import { graphql } from 'gatsby';

import { useTimedTracking } from '../hooks/useTracking';
import RouteEnum from '../RouteEnum';
import Tracking from '../services/Tracking';
import Carousel from '../views/Carousel';
import { StaticImage } from '../views/Image';
import { JourneyBlock } from '../views/Layout/JourneyBlock';
import MobileAppPromo from '../views/Layout/MobileAppPromo';
import PageLayout from '../views/Layout/Page';
import Link from '../views/Link';
import MetaTags from '../views/MetaTags';
import T, { TPrefix } from '../views/Translate';
import EarningsCalculator from '../views/Vehicle/EarningsCalculator';
import {
  type SimpleCalculatorChange,
  VehicleEarningsCalculatorProvider,
} from '../views/Vehicle/VehicleCalculatorContext';

import styles from './offer-car.module.scss';

export const query = graphql`
  query ($language: String!) {
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "offer-car-testimonial" }, lang: { eq: $language } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          frontmatter {
            avatar
            avatar2x
            title
          }
          html
        }
      }
    }

    faqs: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "offer-car-faq" }, lang: { eq: $language } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;

export default function OfferCarPage({ data }) {
  const testimonials = data.testimonials?.edges.map(it => it.node);
  const faqs = data.faqs?.edges.map(it => it.node);

  const track = useTimedTracking(
    (state: SimpleCalculatorChange) => Tracking.track('OFFER_CAR_CALCULATOR_USED', state),
    1000,
  );

  return (
    <PageLayout pageview="offer_car">
      <TPrefix name="offerCar">
        <MetaTags description="meta.description" title="meta.title" />
        <main className="main-container">
          <div className={styles.hero}>
            <T as="h1" className="display primary" html id="hero.title" />
          </div>

          <div className={styles.usps}>
            <div className={styles.usp}>
              <div className={styles.uspHeader}>
                <StaticImage className={styles.uspImage} name="offer-car_usp_1" />
                <T as="h4" id="usp.1.title" />
              </div>
              <T as="p" id="usp.1.description" />
            </div>
            <div className={styles.usp}>
              <div className={styles.uspHeader}>
                <StaticImage className={styles.uspImage} name="offer-car_usp_2" />
                <T as="h4" id="usp.2.title" />
              </div>
              <T as="p" id="usp.2.description" />
            </div>
            <div className={styles.usp}>
              <div className={styles.uspHeader}>
                <StaticImage className={styles.uspImage} name="offer-car_usp_3" />
                <T as="h4" id="usp.3.title" />
              </div>
              <T as="p" id="usp.3.description" />
            </div>
          </div>

          <div id="owner-stories">
            {testimonials.length ? (
              <div className={styles.testimonials}>
                <Carousel
                  autoplay
                  dotClassName={styles.testimonialsDot}
                  dotsClassName={styles.testimonialsDots}
                  fullWidth
                  onDotClick={index => Tracking.track('OFFER_CAR_TESTIMONIAL_SLIDED', index)}
                  showDots
                  timeout={10000}
                  waitForLoad={false}
                >
                  {testimonials.map(it => {
                    return (
                      <article className={styles.testimonial} key={it.id}>
                        <h3>
                          <img alt="" src={it.frontmatter.avatar} srcSet={`${it.frontmatter.avatar2x} 2x`} />
                          {it.frontmatter.title}
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: it.html }} />
                      </article>
                    );
                  })}
                </Carousel>
              </div>
            ) : null}
          </div>

          <div id="earnings-calculator">
            <VehicleEarningsCalculatorProvider onChange={track}>
              <EarningsCalculator
                onFormExpand={() => Tracking.track('OFFER_CAR_CALCULATION_VALUES_SHOWN')}
                onFormShrink={() => Tracking.track('OFFER_CAR_CALCULATION_VALUES_HIDDEN')}
              />
            </VehicleEarningsCalculatorProvider>
          </div>

          {faqs.length ? (
            <div className={styles.faqSection}>
              <div className={styles.faqsTitle}>
                <T as="h2" id="faqs.title" />
              </div>
              <div className={styles.faqsList}>
                {faqs.map(it => (
                  <article className={styles.faq} key={it.id}>
                    <h4>{it.frontmatter.title}</h4>
                    <div dangerouslySetInnerHTML={{ __html: it.html }} />
                  </article>
                ))}

                <Link
                  className="button button-link"
                  onClick={() => Tracking.track('OFFER_CAR_FAQ_CLICKED')}
                  to={RouteEnum.STATIC_FAQ}
                >
                  <T id="faqs.link" />
                  <i className="icon icon-arrow-right-circle" />
                </Link>
              </div>
            </div>
          ) : null}

          <MobileAppPromo />

          <JourneyBlock page="offer_car" />
        </main>
      </TPrefix>
    </PageLayout>
  );
}
